// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-index-js": () => import("C:\\Users\\Ryzen\\Documents\\GitHub\\uptick\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nav-js": () => import("C:\\Users\\Ryzen\\Documents\\GitHub\\uptick\\src\\pages\\nav.js" /* webpackChunkName: "component---src-pages-nav-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "C:\\Users\\Ryzen\\Documents\\GitHub\\uptick\\.cache\\data.json")

